<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="Consumables" icon="consumables-o.png"
          :para="success_message ? success_message : 'Please fill out the fields below to place your order.'"></page-heading>
        <div>
          <table class="reports-accounts" v-if="CustomerConsumableOrderReport">
            <thead>
              <tr>
                <td>Site Id</td>
                <td>Site Name</td>
                <td>Order Date</td>
                <td>No. Orders</td>
                <td>All Bag type</td>
                <td>All Bag Vol</td>
                <td>Delivery Date</td>
                <td>Package Barcode</td>
                <td>Shipped Date</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bag, i) in bags" :key="i">
                <td>{{ site.Customer_Site_id }}</td>
                <td>{{ site.Customer_Site_name }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <template v-if="bagAutomation && currentBagStockSite && currentBagStockBags.length">
            <table class="reports-accounts">
              <thead>
                <tr>
                  <td>Product No</td>
                  <td>Bag Prefix</td>
                  <td>No Of Bags Delivered</td>
                  <td>No Of Bags Collected</td>
                  <td>No Of Bags On Order</td>
                  <td>First Delivery</td>
                  <td>Last Collection</td>
                  <td>Bags Used Per Week</td>
                  <td>Bags At Site</td>
                  <td>Weeks Until Next Order</td>
                  <td>W/C Next Order Date</td>
                  <td>Last Delivery</td>
                  <td>Next Order Date</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(bag, i) in currentBagStockBags" :key="i">
                  <td>{{ bag.Product_Number }}</td>
                  <td>{{ bag.Bag_Prefix }}</td>
                  <td>{{ bag.Number_Of_Bags_Delivered }}</td>
                  <td>{{ bag.Number_Of_Bags_Collected }}</td>
                  <td>{{ bag.Number_Of_Bags_On_Order }}</td>
                  <td>{{ formatDate(bag.First_Delivery) }}</td>
                  <td>{{ bag.Last_Collection }}</td>
                  <td>{{ bag.Bags_Used_Per_Week }}</td>
                  <td>{{ bag.Bags_At_Site }} *</td>
                  <td>{{ bag.Weeks_Until_Next_Order }}</td>
                  <td>{{ formatDate(bag.WC_Next_Order_Date) }}</td>
                  <td>{{ formatDate(bag.Last_Delivery) }}</td>
                  <td>{{ formatDate(bag.Next_Order_Date) }}</td>
                </tr>
              </tbody>
            </table>
            <p class="footnote">* Bags At Site also includes any bags currently on order</p>
          </template>

          <div class="create-order" v-if="!success_message">
            <div id="section1" class="order-section" @focusin="toggleOpen('section1')">
              <div class="order-section-header" @click="toggle('section1')">Select Bags <img
                  src="/images/icons/order-cash-tabs-arrow-n.png" alt="expand" class="expand" /></div>
              <div class="order-section-body">
                <div class="order-section-content">
                  <error-panel v-if="!success_message"
                    content="Total cash inside your bag should never exceed the contracted indemnity limit. If you are unsure, please contact your Head Office."></error-panel>

                  <info-panel v-if="!success_message" content="All packs arrive in sleeves of 50."></info-panel>

                  <template v-if="bagAutomation">
                    <p>29/01/2025: Please be aware that choosing to place an additional order and reset your stock
                      balance will
                      incur
                      a charge of £25</p>
                    <p><input type="checkbox" id="chkTerms" v-model="terms_agreed">&nbsp;<label for="chkTerms">I have
                        read
                        and understood the
                        above terms</label></p>
                  </template>

                  <p v-if="bagAutomation && terms_agreed">
                    Please select your reason for ordering extra bags:
                    <select v-model="reason">
                      <option value="">Please select</option>
                      <option value="Have run out of bags">Have run out of bags</option>
                      <option value="Want to order incase my new bags are not delivered">Want to order incase my new
                        bags are not
                        delivered</option>
                      <option value="Some bags have been damaged - please report damaged bags">Some bags have been
                        damaged - please report
                        damaged bags</option>
                      <option value="other">Other</option>
                    </select>
                    <input type="text" v-model="other_reason" v-if="reason == 'other'" placeholder="Please specify" />
                  </p>

                  <template v-if="!bagAutomation || terms_agreed">
                    <p>Please select the bags you would like to include in your order.</p>
                    <div class="consumables-list">
                      <div class="consumables-list-item" :key="item.id" v-for="item in consumables">
                        <div class="consumables-list-checkbox">
                          <div>
                            <input v-model="selected" :value="item.id" type="checkbox" :id="'checkbox-' + item.id" />
                          </div>
                        </div>
                        <div class="consumables-list-image">
                          <a href @click.prevent="zoomImage(item.image, item.name)">
                            <img @click="zoomImage(item.image, item.name)" :src="item.image" :alt="item.name" /></a>
                        </div>
                        <div class="consumables-list-label">
                          <div>
                            <label :for="'checkbox-' + item.id">{{ item.name }}</label>
                            <a class="zoom">Click image to view
                              larger</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                </div>
              </div>
            </div>
          </div>

          <error-panel v-if="error_message" :content="error_message"></error-panel>
          <success-panel v-if="success_message" :content="success_message"></success-panel>

          <div class="actions" v-if="selected.length">
            <button class="negative" @click="clearFields">
              Clear All Fields <img src="/images/icons/button-icon-cancel.png" alt="Clear all fields" />
            </button>
            <button :disabled="submit_loading" @click="submitOrder">
              <template v-if="submit_loading">
                <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
              </template>
              <template v-if="!submit_loading">
                Submit <img src="/images/icons/button-icon-submit.png" alt="submit" />
              </template>
            </button>
          </div>
        </div>
      </div>
      <right-column></right-column>
    </div>

    <modal :scrollable="true" height="auto" name="zoom" width="90%">
      <a @click.prevent="$modal.hide('zoom')" class="modal-close" href aria-label="Close">
        <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
      </a>
      <div class="zoomed">
        <img :src="zoomsrc" :alt="zoomalt" />
        <span>{{ zoomalt }}</span>
      </div>
    </modal>
  </main>
</template>

<script>
import api from "@/services/api"

export default {
  data() {
    return {
      zoomsrc: "",
      zoomalt: "",
      consumables: [],
      selected: [],
      terms_agreed: false,
      reason: "",
      other_reason: "",
      submit_loading: false,
      error_message: "",
      success_message: "",
      CustomerConsumableOrderCurrentBagStock: null,
      CustomerConsumableOrderReport: null
    }
  },
  created() {
    this.getItems()

    api.request("get", "consumables-report")
      .then(res => {
        this.CustomerConsumableOrderCurrentBagStock = res.CustomerConsumableOrderCurrentBagStock
      })

    api.request("get", "consumable-order-report")
      .then(res => {
        this.CustomerConsumableOrderReport = res.CustomerConsumableOrderReport
      })
  },
  computed: {
    bagAutomation() {
      if (this.$store.state.user_type == "customer") {
        return this.$store.state.customer.bag_automation
      }

      return this.$store.state.hq_user.bag_automation
    },
    currentBagStockBags() {
      if (!this.CustomerConsumableOrderCurrentBagStock) return []
      if (!this.CustomerConsumableOrderCurrentBagStock.sites && !this.CustomerConsumableOrderCurrentBagStock.sites.length) return []
      if (!this.CustomerConsumableOrderCurrentBagStock.sites[0].bags && !this.CustomerConsumableOrderCurrentBagStock.sites[0].bags.length) return []

      return this.CustomerConsumableOrderCurrentBagStock.sites[0].bags
    },
    currentBagStockSite() {
      if (!this.CustomerConsumableOrderCurrentBagStock) return null
      if (!this.CustomerConsumableOrderCurrentBagStock.sites && !this.CustomerConsumableOrderCurrentBagStock.sites.length) return null

      return this.CustomerConsumableOrderCurrentBagStock.sites[0]
    }
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return ''
      return dateString.split(" ")[0].split("-").reverse().join("/")
    },
    zoomImage(src, alt) {
      this.zoomsrc = src
      this.zoomalt = alt
      this.$modal.show("zoom")
    },
    getItems() {
      this.loading = true
      api
        .request("get", "consumable")
        .then(res => {
          this.consumables = res.consumables
        })
        .catch(() => {
          this.error_message = "There has been an error"
        })
        .finally(() => {
          this.loading = false
        })
    },
    clearFields() {
      this.selected = []
    },
    submitOrder() {
      this.error_message = ""
      this.success_message = ""

      if (this.bagAutomation && !this.reason) {
        return (this.error_message = "Please select your reason for ordering extra bags")
      }

      if (this.bagAutomation && this.reason == 'other' && !this.other_reason) {
        return (this.error_message = "Please select your reason for ordering extra bags")
      }

      if (this.selected.length > 3)
        return (this.error_message = "You can only order up to a maximum of 3 items in a single order")

      this.submit_loading = true
      api
        .request("post", "consumable", { ids: this.selected, reason: this.reason == 'other' ? this.other_reason : this.reason })
        .then(() => {
          this.selected = []
          this.success_message =
            "You will receive your bags on one of your upcoming service days. We will also send you a confirmation email with the details of the order."
        })
        .catch((err) => {
          if (err.response.data == 'alreadyExists') {
            this.error_message = "Each consumable can only be ordered once within 7 days"
          } else {
            this.error_message = "There has been an error, please try again"
          }
        })
        .finally(() => {
          this.submit_loading = false
        })
    }
    ,
    toggle(target) {
      var parent = document.getElementById(target)
      var panel = parent.querySelector(".order-section-body")
      if (parent.classList.contains("active")) {
        panel.style.maxHeight = null
        parent.classList.remove("active")
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px"
        parent.classList.add("active")
      }
    },
    toggleOpen(target) {
      if (this.order_reference || this.$route.params.id) {
        var parent = document.getElementById(target)
        var panel = parent.querySelector(".order-section-body")

        panel.style.maxHeight = panel.scrollHeight + "px"
        parent.classList.add("active")
      }
    }
  }
}
</script>

<style scoped>
input:focus-visible {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.order-section {
  transition: all 0.2s;
  margin-top: 2rem;
}

.active .order-section-body {
  max-height: none;
}

.order-section-header {
  cursor: pointer;
  background: #f3f3f3;
  padding: 1.5rem 2rem;
  font-size: 2.5rem;
  transition: all 0.2s;
}

.subheading {
  color: #ca0028;
  padding: 1.5rem 0;
  font-size: 2.5rem;
}

.active .order-section-header {
  background: #fff;
  color: #ca0028;
}

.active {
  box-shadow: 0px 0px 13px -2px #d3d3d3;
}

.order-section-body {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
  border-bottom: 0rem solid transparent;
  width: 100%;
  opacity: 0;
}

.active .order-section-body {
  border-bottom: 0.5rem solid #ca0028;
  padding: 3rem 2rem;
  padding-top: 1rem;
  display: table;
  opacity: 1;
}

.expand {
  float: right;
  height: 2rem;
  position: relative;
  top: 0.5rem;
  transition: all 0.4s;
}

.active .expand {
  transform: rotate(270deg);
}

.order-section-body p {
  margin-bottom: 2rem;
}

.order-section-body p.small {
  font-size: 1.5rem;
}

.order-section-body p:last-child {
  margin-bottom: 0;
}

.consumables-list-item {
  display: grid;
  grid-template-columns: 0.5fr 2fr 6fr;
  gap: 2rem;
}

.consumables-list-checkbox>div {
  text-align: center;
  width: 100%;
}

.consumables-list-checkbox input {
  transform: scale(1.5);
  cursor: pointer;
}

.consumables-list-label {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
}

.consumables-list-label a {
  display: block;
  font-weight: normal;
  font-size: 1.5rem;
  cursor: default;
}

.consumables-list-item>div {
  display: flex;
  align-items: center;
}

.consumables-list-image img {
  width: 15rem;
  padding: 1.5rem 0;
  margin: auto;
  cursor: pointer;
}

.zoom {
  margin-top: 0.5rem;
}

.zoom svg {
  margin-left: 0.5rem;
}

.zoomed {
  text-align: center;
  font-weight: bold;
}

.zoomed img {
  display: block;
  margin: auto;
  margin-bottom: 1rem;
}

.reports-accounts {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
  font-size: 1.4rem;
}

.footnote {
  font-size: 1.6rem;
  margin-top: 1rem;
}

.reports-accounts td {
  padding: 0.5rem 0.5rem;
  border: 1px solid #eee;
}

.reports-accounts thead td {
  background: #E84343;
  color: white;
  font-weight: bold;
}

.reports-accounts tr:nth-child(even) td {
  background: #F2DCDB;
}


@media screen and (max-width: 600px) {
  .actions button {
    font-size: 1.8rem;
  }

  .actions button img {
    height: 1.5rem;
    margin-bottom: 0;
  }

  .consumables-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}
</style>
